import React from 'react';

const SlackIcon = () => (      
     <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.00004 256.00004">
<title>Slack</title>
<path d="M217.979,128A29.98715,29.98715,0,1,0,182,80.01646V56a29.98957,29.98957,0,0,0-54-17.97912A29.98712,29.98712,0,1,0,80.0166,74H56a29.98951,29.98951,0,0,0-17.979,54A29.98718,29.98718,0,1,0,74,175.98369V200a29.98958,29.98958,0,0,0,54,17.97913A29.98712,29.98712,0,1,0,175.9834,182H200a29.98951,29.98951,0,0,0,17.979-54ZM200,86a18,18,0,0,1,0,36H182V104A18.02063,18.02063,0,0,1,200,86ZM152,38a18.02063,18.02063,0,0,1,18,18v48a18.02062,18.02062,0,0,1-18,18H134V56A18.02063,18.02063,0,0,1,152,38ZM86,56a18,18,0,0,1,36,0V74H104A18.02062,18.02062,0,0,1,86,56ZM38,104A18.02063,18.02063,0,0,1,56,86h48a18.02063,18.02063,0,0,1,18,18v18H56A18.02062,18.02062,0,0,1,38,104Zm18,66a18,18,0,0,1,0-36H74v18A18.02062,18.02062,0,0,1,56,170Zm48,48a18.02062,18.02062,0,0,1-18-18V152a18.02063,18.02063,0,0,1,18-18h18v66A18.02062,18.02062,0,0,1,104,218Zm66-18a18,18,0,0,1-36,0V182h18A18.02063,18.02063,0,0,1,170,200Zm30-30H152a18.02062,18.02062,0,0,1-18-18V134h66a18,18,0,0,1,0,36Z"/>
</svg>
);

export default SlackIcon;
